import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData, setQSData } from 'QueryStringController'
import ThreeToFourV2 from 'checkIn/steps/EmotionIntensity/ThreeToFourV2'

const SCREEN_NAME = 'emotionIntensity'

const EmotionIntensity: RouteComponent = () => {
  const { student } = useCheckIn()

  const { feeling } = getQSData()

  if (!student) return null

  const { nextFunction } = FLOWS['kid'][SCREEN_NAME]

  const selectEmotionIntensity = (feeling_intensity: number | null) => {
    if (!feeling_intensity) return null
    setQSData({ feeling_intensity })
    nextFunction(window.location.search)
  }

  return <ThreeToFourV2 selectEmotionIntensity={selectEmotionIntensity} feeling={feeling} />
}

export default EmotionIntensity
