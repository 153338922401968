import React, { useState } from 'react'
import { lightenColor } from 'utils'
import { HotlinesModal } from './HotlinesModal'
import styled from 'styled-components'

const StyledHotlineButton = styled.button`
  padding: 0.75rem 3rem;
  background-image: linear-gradient(to bottom, #e9573e, ${lightenColor('#e9573e', -25)});
  color: white;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 1.275rem;
  line-height: 2rem;
`

interface Props {
  customHotlineText?: string
  countryHotlineText?: string
  countryCode: string | undefined
}

export const HotlinesButton = ({ customHotlineText, countryHotlineText, countryCode }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledHotlineButton onClick={() => setOpen(true)}>Are you in crisis?</StyledHotlineButton>
      <HotlinesModal
        open={open}
        onClose={() => setOpen(false)}
        countryHotlineText={countryHotlineText}
        customHotlineText={customHotlineText}
        countryCode={countryCode}
      />
    </>
  )
}
