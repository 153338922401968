import React, { useState } from 'react'
import { HotlinesModal } from './HotlinesModal'
//import { SecondaryButton } from './SecondaryButton'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

interface Props {
  customHotlineText?: string
  countryHotlineText?: string
  countryCode: string | undefined
}

export const HotlinesButton = ({ customHotlineText, countryHotlineText, countryCode }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="text-white font-bold underline uppercase hover:text-teal-50"
      >
        <div className="flex gap-2 items-center justify-center p-4 ">
          <ExclamationTriangleIcon className="h-6 w-6" />
          <span>Are you in crisis?</span>
        </div>
      </button>
      <HotlinesModal
        open={open}
        onClose={() => setOpen(false)}
        countryHotlineText={countryHotlineText}
        customHotlineText={customHotlineText}
        countryCode={countryCode}
      />
    </>
  )
}
