import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Paragraph } from 'checkIn/components/teen/Paragraph'
import ButtonNext from 'components/ButtonNext'
import React from 'react'
import { RouteComponent } from 'types'

const DemoIndex: RouteComponent = () => {
  return (
    <CheckInContainer bg="/images/teen/cadet_upside_on_moon.png">
      <Paragraph>Thanks for trying out the Closegap Demo</Paragraph>
      <Paragraph>Would you like to try another check-in?</Paragraph>

      <ButtonNext style={{ marginTop: '1rem' }} nextURL={'/demo'} handleMixpanel={() => {}}>
        Start Over
      </ButtonNext>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'https://closegap.org'}
        handleMixpanel={() => {}}
      >
        Learn More
      </ButtonNext>
      <ButtonNext style={{ marginTop: '1rem' }} nextURL={'/sign-up'} handleMixpanel={() => {}}>
        Exit Demo
      </ButtonNext>
    </CheckInContainer>
  )
}

export default DemoIndex
