import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import { WelcomeTeen } from 'checkIn/steps/Welcome/Teen'
import { WelcomeKid } from 'checkIn/steps/Welcome/Kid'
import { WelcomeNonReader } from 'checkIn/steps/Welcome/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { checkInTrack, isStudent, resetCheckInIdempotencyKey } from 'students/helper'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { useMixpanel } from 'mixpanel'
import { useCroods } from 'croods'
import { setQSData } from 'QueryStringController'
import { featureEnabled } from 'featureFlags'
import { WelcomeFiveToEight } from './FiveToEight'

const SCREEN_NAME = 'welcome'

const Welcome: RouteComponent = ({ studentId }) => {
  const mixpanel = useMixpanel()
  const [{ currentUser }] = useCurrentUser()
  const { student } = useCheckIn()
  const [{ fetchingList }] = useCroods({
    fetchOnMount: true,
    customPath: `students/${studentId}/impact_measurements`,
    name: 'impact_measurements',
    afterSuccess: ({ data }) => {
      setQSData({ impactMeasurement: data.timeForMeasurement })
    },
    after4xx: () => {
      console.log('error')
    },
  })

  useEffect(() => {
    if (student) {
      const eventTitle = !student.latestCheckIn
        ? 'View First Welcome Student'
        : 'View Welcome Student'

      checkInTrack(mixpanel, eventTitle, {
        studentId: student.id,
        grade: student.grade,
      })
    }
    resetCheckInIdempotencyKey()
  }, [student]) // eslint-disable-line

  if (currentUser && student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]
    const showActivities = featureEnabled(currentUser, 'standalone_activities')
    const showMessages = featureEnabled(currentUser, 'message_center')
    isStudent(currentUser.role)

    switch (student.studentType) {
      case 'teen':
        if (featureEnabled(currentUser, 'new_teen_check_in')) {
          return (
            <WelcomeFiveToEight
              student={student}
              nextFunction={nextFunction}
              isStudent={isStudent(currentUser.role)}
              showActivities={showActivities}
              showMessages={showMessages}
              currentUser={currentUser}
            />
          )
        } else {
          return (
            <WelcomeTeen
              student={student}
              nextFunction={nextFunction}
              isStudent={isStudent(currentUser.role)}
              showActivities={showActivities}
            />
          )
        }
      case 'kid':
        return (
          <WelcomeKid
            student={student}
            nextFunction={nextFunction}
            isStudent={isStudent(currentUser.role)}
            loading={fetchingList}
            showActivities={showActivities}
            showMessages={showMessages}
          />
        )
      case 'non-reader':
        return (
          <WelcomeNonReader
            currentUser={currentUser}
            student={student}
            nextFunction={nextFunction}
            isStudent={isStudent(currentUser.role)}
          />
        )
    }
  }

  return null
}

export default Welcome
