export const MOCK_USER = {
  acceptedTerms: true,
  birthdate: '',
  changePasswordOnNextLogin: false,
  createdAt: new Date(),
  displayName: '',
  email: '',
  grade: '3',
  firstName: 'Cadet',
  firstSchoolAccessPending: false,
  honorificTitle: '',
  id: -1,
  lastName: '',
  phoneNumber: null,
  school: null,
  student: null,
  studentId: -1,
  featureFlags: ['3-4_check_in_2024'],
}
