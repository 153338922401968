import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Paragraph } from 'checkIn/components/teen/Paragraph'
import ButtonNext from 'components/ButtonNext'
import React from 'react'
import { RouteComponent } from 'types'

const DemoIndex: RouteComponent = () => {
  return (
    <CheckInContainer bg="/images/teen/cadet_upside_on_moon.png">
      <Paragraph>Welcome to the Closegap Demo</Paragraph>
      <Paragraph>None of your data will be saved during this session.</Paragraph>
      <Paragraph>Which check-in would you like to try?</Paragraph>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'/demo/nonReader/choose-emotion'}
        handleMixpanel={() => {}}
      >
        Grades PK-2
      </ButtonNext>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'/demo/elementarySchool/choose-emotion'}
        handleMixpanel={() => {}}
      >
        Grades 3-4
      </ButtonNext>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'/demo/middleSchool/select-color'}
        handleMixpanel={() => {}}
      >
        Grades 5-8
      </ButtonNext>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'/demo/highSchool/select-color'}
        handleMixpanel={() => {}}
      >
        Grades 9-12
      </ButtonNext>
      <ButtonNext
        style={{ marginTop: '1rem' }}
        nextURL={'https://closegap.org'}
        handleMixpanel={() => {}}
      >
        Learn More
      </ButtonNext>
      <ButtonNext style={{ marginTop: '1rem' }} nextURL={'/sign-up'} handleMixpanel={() => {}}>
        Exit Demo
      </ButtonNext>
    </CheckInContainer>
  )
}

export default DemoIndex
