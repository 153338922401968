import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { lightenColor } from 'utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  padding: 2rem;
  @media screen and (max-width: 600px) {
    padding: 1rem;
    max-width: 100%;
    max-height: 100vh;
  }
  position: relative;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 50vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 0 1rem;
  margin-bottom: 1rem;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(148, 148, 148, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(148, 148, 148, 0.4);
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(148, 148, 148, 1);
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: #353535;
  line-height: 160%;
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`

const Paragraph = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  color: #353535;
  margin-bottom: 1rem;
  .trix-content a {
    color: #0d9488;
    text-decoration: underline;
  }
`

const Link = styled.a`
  color: #0d9488;
  text-decoration: underline;
`

const CloseButton = styled.button`
  padding: 0.5rem 3rem;
  background-image: linear-gradient(to bottom, #04c8c8, ${lightenColor('#04C8C8', -25)});
  color: white;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9999px;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
`

interface Props {
  open: boolean
  onClose: () => void
  customHotlineText?: string
  countryHotlineText?: string
  countryCode: string | undefined
}

export const HotlinesModal = ({
  open,
  onClose,
  customHotlineText,
  countryHotlineText,
  countryCode,
}: Props) => {
  const sanitizedCustomHotlineText =
    // Check in case customHotlineText is undefined
    typeof customHotlineText === 'string' ? DOMPurify.sanitize(customHotlineText) : ''

  const sanitizedCountryHotlineText =
    // Check in case countryHotlineText is undefined
    typeof countryHotlineText === 'string' ? DOMPurify.sanitize(countryHotlineText) : ''

  const defaultUSText = (
    <>
      <Title>US Hotline Information</Title>
      <TextContainer>
        <Paragraph>
          If you are in crisis, please call the mental health hotline and <strong>dial 988</strong>.
          You can also text <strong> &apos;HERO&apos;</strong> to <strong>741741</strong>.
        </Paragraph>
        <Paragraph>
          Get help through articles
          <br />
          <ul>
            <li>
              <Link
                href="https://www.inspiringchildren.org/mental-tools-shift-your-focus"
                target="_blank"
              >
                Shift your focus
              </Link>
            </li>
          </ul>
        </Paragraph>
      </TextContainer>
    </>
  )

  const defaultInternationalText = (
    <>
      <Title>International Hotline Information</Title>
      <TextContainer>
        <Paragraph>
          If you are in a crisis, please consult the following list and call the mental health
          hotline for your country:
          <br />
          <Link href="https://blog.opencounseling.com/suicide-hotlines/" target="_blank">
            International Crisis Hotlines
          </Link>
        </Paragraph>
      </TextContainer>
    </>
  )

  const schoolCountryHotlineText = (
    <>
      <Title>Hotline Information</Title>
      <TextContainer>
        <Paragraph dangerouslySetInnerHTML={{ __html: sanitizedCountryHotlineText }}></Paragraph>
      </TextContainer>
    </>
  )

  const getHotlineText = () => {
    if (sanitizedCountryHotlineText) return schoolCountryHotlineText
    else if (countryCode === 'US') return defaultUSText
    else return defaultInternationalText
  }
  return (
    <Dialog onClose={onClose} open={open}>
      <Container>
        {getHotlineText()}
        <TextContainer>
          <Paragraph dangerouslySetInnerHTML={{ __html: sanitizedCustomHotlineText }}></Paragraph>
        </TextContainer>

        <CloseButton type="button" onClick={() => onClose()}>
          Close
        </CloseButton>
      </Container>
    </Dialog>
  )
}
