const contentByQuestion = {
  hasEaten: {
    title: 'Are you hungry right now?',
    leftButtonText: 'No, I’m not.',
    rightButtonText: 'Yes, I am.',
    eventName: 'Check-in: Are you hungry right now?',
    image: {
      src: '/images/questions/breakfast.png',
      alt: '',
    },
    audio: {
      left: 'not-hungry',
      right: 'hungry',
    },
  },
  hasSlept: {
    title: 'Did you sleep well last night?',
    leftButtonText: 'Yes I did.',
    rightButtonText: 'No, I didn’t.',
    eventName: 'Check-in: Did you sleep last night?',
    image: {
      src: '/images/questions/sleep.png',
      alt: '',
    },
    audio: {
      left: 'slept-well',
      right: 'did-not-sleep-well',
    },
  },
  hurtOrSick: {
    title: 'Are you hurt or sick?',
    leftButtonText: 'Yes, I am.',
    rightButtonText: 'No, I’m not.',
    eventName: 'Check-in: Are you hurt or sick?',
    image: {
      src: '/images/questions/plaster.png',
      alt: '',
    },
    audio: {
      left: 'is-hurt',
      right: 'not-hurt',
    },
  },
  okAtHome: {
    title: 'Is everything okay at home?',
    leftButtonText: 'Yes, it’s OK.',
    rightButtonText: 'No, it’s not OK.',
    eventName: 'Check-in: Is everything okay at home?',
    image: {
      src: '/images/questions/home.png',
      alt: '',
    },
    audio: {
      left: 'is-ok-at-home',
      right: 'not-ok-at-home',
    },
  },
  bulliedAtSchool: {
    title: 'Is someone at school being unkind to you?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Are you being bullied at school?',
    image: {
      src: '/images/questions/bullying.png',
      alt: '',
    },
    audio: {
      left: 'is-bullied',
      right: 'not-bullied',
    },
  },
  feelingStressed: {
    title: 'Are you feeling stressed?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Are you feeling stressed?',
  },
  feelingLonely: {
    title: 'Are you feeling lonely?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Are you feeling lonely?',
  },
  overwhelmedByWorkload: {
    title: 'Is your workload at school overwhelming you?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Is your workload at school overwhelming you?',
  },
  okInSocialLife: {
    title: 'Is everything okay in your social life?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Is everything okay in your social life?',
  },
  feelingTired: {
    title: 'Are you feeling tired?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Are you feeling tired?',
  },
  feelingWorthless: {
    title: 'Are you feeling like you are worth less than others?',
    leftButtonText: 'Yes.',
    rightButtonText: 'No.',
    eventName: 'Check-in: Are you feeling like you are worth less than others?',
  },
} as const

export default contentByQuestion
