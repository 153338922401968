import React from 'react'
import CadetOnBottom from 'checkIn/components/kid/CadetOnBottomScreen'

import { CHECK_IN_WELCOME_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import AudioPlayer from 'checkIn/AudioPlayer'
import { ButtonNext } from 'checkIn/components/ButtonNext'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  return (
    <CadetOnBottom
      title="My name is Cadet."
      text="I’m so excited to explore emotions with you! "
      backgroundImage="url('/images/cadet_on_the_moon_2.png')"
      audio={CHECK_IN_WELCOME_PAGE_THREE_TO_FOUR.autoplay}
    >
      <AudioPlayer importAudio={CHECK_IN_WELCOME_PAGE_THREE_TO_FOUR.readyToGo}>
        <ButtonNext onClick={() => nextFunction(window.location.search)}>
          <span className="font-bold">Ready to Go!</span>
        </ButtonNext>
      </AudioPlayer>
    </CadetOnBottom>
  )
}

export default Kid
