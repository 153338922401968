import React from 'react'

import AudioPlayer from 'checkIn/AudioPlayer'
import { QUESTIONS_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import { ButtonNext } from 'checkIn/components/ButtonNext'

const CheckInButtonGroup = ({
  leftButtonText,
  handleLeftButton,
  rightButtonText,
  handleRightButton,
  audio,
}) => (
  <div className="flex flex-col items-center justify-center w-full gap-10 p-10 mt-10 text-center sm:flex-row">
    <AudioPlayer importAudio={QUESTIONS_PAGE_THREE_TO_FOUR[audio.left]}>
      <ButtonNext className="w-full max-w-sm" aria-label="left-button" onClick={handleLeftButton}>
        <span className="font-medium whitespace-nowrap">{leftButtonText}</span>
      </ButtonNext>
    </AudioPlayer>
    <AudioPlayer importAudio={QUESTIONS_PAGE_THREE_TO_FOUR[audio.right]}>
      <ButtonNext className="w-full max-w-sm" aria-label="right-button" onClick={handleRightButton}>
        <span className="font-medium whitespace-nowrap">{rightButtonText}</span>
      </ButtonNext>
    </AudioPlayer>
  </div>
)

export default CheckInButtonGroup
