import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

import { QUESTIONS_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import { checkInTrack, setLocalStorageCheckIn } from 'students/helper'
import withMixpanel from 'mixpanel/withMixpanel'
import Screen from 'checkIn/components/kid/Screen'

import { ButtonNext } from 'checkIn/components/ButtonNext'
import SecondaryButton from 'checkIn/components/SecondaryButton'
import { getQSData } from 'QueryStringController'

const styles = (theme) => ({
  cssUnderline: {
    color: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.white}`,
  },
  cssFocused: {
    '&:after': {
      borderBottomColor: theme.colors.white,
    },
  },
  cssLabel: {
    left: 'initial',
    color: theme.colors.white,
    '&$cssFocused': {
      color: theme.colors.white,
    },
  },
})

const Notes = withMixpanel(
  withStyles(styles)(({ classes, mixpanel, flow }) => {
    const [text, setText] = useState('')
    const { nextFunction } = flow

    const qs = getQSData()

    const onClickButton = (value = null) => {
      const btnEventMessage = value ? 'Send clicked' : 'No thanks clicked'
      checkInTrack(mixpanel, btnEventMessage, qs)
      setLocalStorageCheckIn('notes', value)

      nextFunction(window.location.search)
    }

    return (
      <Screen
        className="h-screen !mt-0"
        label="Do you want to share anything else?"
        title="You can provide more information about your check-in, 
          express how you're feeling in your own words, 
          or describe what you need help with."
        titleAudio={QUESTIONS_PAGE_THREE_TO_FOUR.provideMoreInfo}
      >
        <div className="container flex flex-col items-center mx-10 mt-[12vh]">
          <TextField
            autoFocus
            id="text-here"
            label="Text here"
            multiline
            rowsMax="5"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="w-screen max-w-xs sm:max-w-sm"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                focused: classes.cssFocused,
                underline: classes.cssUnderline,
              },
            }}
          />
          <ButtonNext
            className="w-full mt-10"
            disabled={text.length < 1}
            onClick={() => onClickButton(text)}
          >
            <span className="font-medium">Send</span>
          </ButtonNext>
          <SecondaryButton
            className="mt-10"
            onClick={() => onClickButton(null)}
            style={{ width: 240 }}
          >
            <span className="mx-10 font-medium">No, thanks.</span>
          </SecondaryButton>
        </div>
      </Screen>
    )
  }),
)

export default Notes
