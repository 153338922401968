import React, { useState } from 'react'
import planetList from 'checkIn/constants/threeToFour/planetList'
import BackAndNextButtonsV2 from 'checkIn/components/kid/BackAndNextButtonsV2'
import Screen from 'checkIn/components/kid/Screen'
import IntensitySelector from './IntensitySelectorV3'
import styled from 'styled-components'

import AudioPlayer from 'checkIn/AudioPlayer'
import {
  FEELING_SCALE_THREE_TO_FOUR,
  HOW_ARE_YOU_FEELING_THREE_TO_FOUR,
} from 'checkIn/audiosThreeToFour'

export const Label = styled.p`
  color: #fff5f5;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  margin: 0;
  padding: 0;
  // text-transform: uppercase;
`

const PlanetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-top: 5vh;
  margin-bottom: 8vh;
  @media screen and (max-width: 600px) {
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`

const Image = styled.img`
  width: 8rem;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 6rem;
  }
`

type Props = {
  feeling: string
  selectEmotionIntensity: (params: number | null) => void
}

const ThreeToFourV2 = ({ feeling, selectEmotionIntensity }: Props) => {
  const [intensity, setIntensity] = useState<number | null>(null)

  const planet = planetList.filter((planet) => planet.feeling === feeling)[0]
  return (
    <Screen
      className="p-4 md:p-12 flex flex-col min-h-[100vh] md:!justify-between gap-12 !justify-start h-full"
      // title={`On a scale from 1 (not at all) to 5 (so much), how ${feeling} are you feeling?`}
    >
      <AudioPlayer
        importAudio={FEELING_SCALE_THREE_TO_FOUR[planet.feeling]}
        styleButton={{ marginLeft: 0 }}
      >
        <p className="text-2xl sm:text-4xl text-white sm:pt-4 mx-auto text-center z-10">{`On a scale from 1 (not at all) to 5 (so much), how ${feeling} are you feeling?`}</p>
      </AudioPlayer>
      <PlanetContainer>
        <AudioPlayer
          importAudio={HOW_ARE_YOU_FEELING_THREE_TO_FOUR[planet.feeling]}
          className="justify-center gap-3 mt-10"
          styleButton={{
            alignSelf: 'flex-end',
            marginLeft: -56,
            marginBottom: 10,
          }}
        >
          <Image src={planet.src} alt={''} />
        </AudioPlayer>
        <Label>{planet.label}</Label>
      </PlanetContainer>

      <IntensitySelector setIntensity={setIntensity} selectedIntensity={intensity} />

      <BackAndNextButtonsV2
        showBackButton
        hiddenNextButton={!Boolean(intensity)}
        nextButtonOnClick={() => selectEmotionIntensity(intensity)}
      />
    </Screen>
  )
}

export default ThreeToFourV2
