import React from 'react'
import { map, some } from 'lodash'
import clsx from 'clsx'
import slugify from 'slugify'

import { CircularItem } from 'checkIn/components/CircularElements'
import Moon from 'checkIn/steps/BodySensations/ThreeToFourBodySensations/Moon'
import planetList from 'checkIn/constants/threeToFour/planetList'

import { SOMATIC_SENSATIONS_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  feelingContent: typeof planetList[number]
  selectedSensations: string[]
  moonStyle:
    | {
        position: string
        transform: null
        top: number
        left: number
      }
    | undefined
  isConfirmationPage: boolean
  handleMoon: (item: string) => void
  startDeg: number
  endDeg: number
  distance: string
}

const FeelingContent = ({
  feelingContent,
  selectedSensations,
  moonStyle,
  isConfirmationPage,
  handleMoon,
  ...props
}: Props) => {
  const elements = map(feelingContent?.sensations, (option, index) => {
    const slug = slugify(option, { lower: true })
    const selected = some(selectedSensations, (item) => item.toLowerCase() === option.toLowerCase())

    const { startDeg, endDeg, distance } = props

    return (
      <CircularItem
        key={index}
        style={moonStyle}
        length={feelingContent?.sensations?.length}
        startDeg={startDeg}
        endDeg={endDeg}
        distance={distance}
        index={index}
        selected={false}
      >
        <AudioPlayer
          className={clsx(isConfirmationPage && !selected && 'hidden')}
          styleButton={{ marginLeft: -40, marginBottom: 8 }}
          importAudio={
            SOMATIC_SENSATIONS_THREE_TO_FOUR[slug as keyof typeof SOMATIC_SENSATIONS_THREE_TO_FOUR]
          }
        >
          <Moon
            key={index}
            handleMoon={handleMoon}
            hideUnselected={isConfirmationPage}
            selected={selected}
            option={option}
            isConfirmationPage={isConfirmationPage}
          />
        </AudioPlayer>
      </CircularItem>
    )
  })

  return <div>{elements}</div>
}

export default FeelingContent
