import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

import { SURVEY_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import { setLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'
import TitleWithAudio from '../components/TitleWithAudio'
import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  nextFunction: (impactMeasurement?: boolean) => Promise<void>
}

const ButtonContainer = styled.div`
  max-width: 468px;
  width: 100%;
  margin: 2rem 0;
  // padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 6rem;
  border-radius: 16px;
  @media screen and (max-width: 600px) {
    gap: 1rem;
  }
`

const ColorButton = styled.button<{ background: string; selected: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  min-height: 80px;
  background: ${({ background }) => background};
  border-radius: 16px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
  outline: ${({ background, selected }) => `${selected ? '3' : '0'}px solid ${background}`};
  outline-offset: 2px;
`

const Kid = ({ nextFunction }: Props) => {
  const [selectedOption, setselectedOption] = React.useState<string | null>(null)
  const color = '#E96F49'

  const onClick = (number: string) => {
    setLocalStorageCheckIn('impactQ5', number)
    setselectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="" className="max-w-4xl mx-auto">
      <TitleWithAudio audio={SURVEY_THREE_TO_FOUR.q5}>
        How many adults at school do you feel close to?
      </TitleWithAudio>

      <ButtonContainer>
        {['None', '1 adult', '2 or 3 adults', '4 or more adults'].map((number: string) => (
          <AudioPlayer
            key={number}
            importAudio={SURVEY_THREE_TO_FOUR[number as keyof typeof SURVEY_THREE_TO_FOUR]}
          >
            <ColorButton
              background={color}
              selected={selectedOption === number}
              onClick={() => onClick(number)}
            >
              {number}
            </ColorButton>
          </AudioPlayer>
        ))}
      </ButtonContainer>
      {selectedOption ? (
        <div className="absolute bottom-0 w-full">
          <BackAndNextButtons nextButtonText="Next" nextButtonOnClick={() => nextFunction(true)} />
        </div>
      ) : null}
    </CheckInContainer>
  )
}

export default Kid
