import React from 'react'

import { DashboardScreenV2 } from 'components/DashboardScreenV2'
import validateUserFields from 'auth/validateUserFields'

import type { User } from 'types'

type Props = { currentUser: User; schoolId: string }
const Home = ({ currentUser, schoolId }: Props) => {
  return (
    <DashboardScreenV2
      currentUser={currentUser}
      propsHeader={{
        propsMenuSchool: {
          shoAllPremiumSchools: false,
          navigateOnChange: true,
          schoolIdFromURL: schoolId,
          navigateOnChangeFn(school) {
            return `/v2/schools/${school}/messages`
          },
        },
      }}
    >
      <iframe
        src={`${process.env.REACT_APP_API_URL}/app/educators/schools/${schoolId}/messages`}
        className="w-full max-h-[calc(100vh-92px)] min-h-[calc(100vh-92px)]"
        title="Home"
      />
    </DashboardScreenV2>
  )
}

export default validateUserFields(Home)
