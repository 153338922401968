import React from 'react'

import Screen from 'checkIn/components/kid/Screen'
import { setQSData } from 'QueryStringController'

import CheckInButtonGroup from 'checkIn/steps/Questions/Kid/CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/kid/contentByQuestion'
import { QUESTIONS_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import { kebabCase } from 'lodash'

const Kid = ({ nextFunction, questionKey, school }) => {
  const question = contentByQuestion[questionKey]
  const { image, audio } = question

  return (
    <Screen
      title={question.title}
      className="h-screen mt-0"
      titleAudio={QUESTIONS_PAGE_THREE_TO_FOUR[kebabCase(questionKey)]}
    >
      <img src={image.src} alt={image.alt} className="mt-10 mb-20" />
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school)
        }}
        audio={audio}
      />
    </Screen>
  )
}

export default Kid
