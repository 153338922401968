import moment from 'moment'

export const isWithinSchoolOpeningHours = (checkInAvailable?: boolean) => {
  // if checkInAviailible is not undefined or nil, but is true or false then return the value of chckInAvailible
  if (checkInAvailable != null) {
    return checkInAvailable
  }
  const format = 'hh:mm:ss'
  const startTime = moment('07:29:59', format)
  const endTime = moment('15:00:00', format)
  return moment().isBetween(startTime, endTime)
}

function isWeekendToday(): boolean {
  return moment().day() % 6 === 0
}

function isStudent(role: string): boolean {
  return role === 'student'
}

function isOlderTeen(grade: string): boolean {
  return parseInt(grade) >= 11 || String(grade).toLowerCase() === 'hed'
}

export function isCheckInAvailable(
  currentUserRole: string,
  studentGradeLevel: string,
  checkInAvailable?: boolean,
): boolean {
  if (!isStudent(currentUserRole)) {
    return true
  }
  if (isWithinSchoolOpeningHours(checkInAvailable) && !isWeekendToday()) {
    return true
  }
  if (isOlderTeen(studentGradeLevel)) {
    return true
  }
  return false
}
