import React from 'react'
import styled from 'styled-components'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import { FUELV2 } from 'fuel'
import Image from 'components/ResponsiveImage'
import { isWidthUp, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import AudioPlayer from 'checkIn/AudioPlayer'
import { ENERGY_LEVELS_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import { User } from 'types'

export const FuelContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin: 0px auto;
  position: relative;
  text-align: center;
  justify-content: center;
  padding: 1.5rem 6rem;
  gap: 16px;
`

export const FuelContainerNoPaddingY = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin: 0px auto;
  position: relative;
  text-align: center;
  justify-content: center;
  padding: 0px 6rem;
  gap: 16px;
`

export const FuelButton = styled.button<{ bsColor: string; transparent?: boolean }>`
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #e5e5e5;
  font-size: 24px;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  opacity: ${({ transparent }) => (transparent ? '20%' : '100%')};

  img {
    height: 100%;
    margin-bottom: 16px;
    box-shadow: ${({ bsColor }) => `0px 0px 24px 0px ${bsColor}`};
    border: 6px solid ${({ bsColor }) => bsColor};
    border-radius: 16px;
  }
`

export const FuelContainerMobile = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column-reverse;
  margin: 10px auto;
  position: relative;
  text-align: center;
  justify-content: center;
  padding: 1.5rem 4rem;
  gap: 10px;
`

export const FuelButtonMobile = styled.button<{ bsColor: string; transparent?: boolean }>`
  // width: 100%;
  // max-width: 220px;
  display: flex;
  flex-direction: column;
  // height: 100%;
  justify-content: center;
  align-items: center;
  color: #e5e5e5;
  font-size: 16px;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 5px;

  opacity: ${({ transparent }) => (transparent ? '20%' : '100%')};

  img {
    border: 4px solid ${({ bsColor }) => bsColor};
    height: 100%;
    width: auto;
    margin-bottom: 10px;
    box-shadow: ${({ bsColor }) => `0px 0px 24px 0px ${bsColor}`};
    border-radius: 16px;
  }
`

export const listFuelLink = Object.values(FUELV2)

type Props = {
  width: Breakpoint
  selectFuelFunction: (value: string, user?: User) => void
  currentUser?: User
}
const ThreeToFour = ({ selectFuelFunction, width, currentUser }: Props) => {
  return (
    <Screen
      label="Pick the image that matches your energy level"
      title="How much fuel is in your spaceship?"
      titleAudio={ENERGY_LEVELS_THREE_TO_FOUR.instructions}
    >
      {isWidthUp('md', width) ? (
        <FuelContainerNoPaddingY>
          {listFuelLink.map(({ value, label, image, bsColor3to4 }) => (
            <AudioPlayer
              key={value}
              importAudio={
                ENERGY_LEVELS_THREE_TO_FOUR[value as keyof typeof ENERGY_LEVELS_THREE_TO_FOUR]
              }
              className="relative"
              styleButton={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translate(0%, calc(-50% - 64px))',
              }}
            >
              <FuelButton
                onClick={() => selectFuelFunction(value, currentUser)}
                bsColor={bsColor3to4}
              >
                <Image src={`/images/energyLevels/3to4/${image}`} alt={''} />
                <span className="mt-4">{label}</span>
              </FuelButton>
            </AudioPlayer>
          ))}
        </FuelContainerNoPaddingY>
      ) : (
        <FuelContainerMobile>
          {listFuelLink.map(({ value, label, image, bsColor3to4 }) => (
            <FuelButtonMobile
              key={value}
              onClick={() => selectFuelFunction(value, currentUser)}
              bsColor={bsColor3to4}
            >
              <Image src={`/images/energyLevels/3to4/mobile/${image}`} alt={''} />
              {label}
            </FuelButtonMobile>
          ))}
        </FuelContainerMobile>
      )}

      <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
        <BackAndNextButtons showBackButton hiddenNextButton />
      </div>
    </Screen>
  )
}

export default withWidth()(ThreeToFour)
