import React from 'react'
import PrintIcon from '@material-ui/icons/Print'
import { Link } from '@reach/router'

import { useSchools } from 'Educators/SchoolsContext'
import { welcomePath, isSchoolPremium, isPremium } from 'students/helper'
import AdultsOnAccount from 'components/adults-on-account'
import ButtonCSVExport from 'Educators/students/ButtonCSVExport'
import { identity, map, pickBy } from 'lodash'
import { useMixpanel } from 'mixpanel'

import type { User, Student } from 'types'
import Breadcrumbs from 'components/Breadcrumbs'
import { featureEnabled } from 'featureFlags'
import { MessagesIcon } from 'components/DashboardScreenV3'

type Props = {
  student: Student
  currentTab: string
  currentUser: User
  query: {
    from: string
    to: string
  }
}
const EditHeader = ({ student, currentUser, currentTab, query }: Props) => {
  const mixpanel = useMixpanel()
  const { school, schoolId } = useSchools()
  const handlePrint = () => window.print()

  const triggerMixpanelEvent = () =>
    mixpanel.track('Checking through educator student profile clicked', {
      studentId: student.id,
    })

  return (
    <div>
      {featureEnabled(currentUser, 'progress_monitoring') && (
        <div className="mb-4 flex items-center gap-4 rounded-md text-white font-semibold px-4 py-2 bg-purple-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
            />
          </svg>
          <span>
            An improved version of the Student Profile is coming soon,{' '}
            <Link
              to={`/v2/schools/${student.schoolId}/students/${student.id}`}
              className="hover:text-purple-50 hover:underline"
            >
              click here to preview it
            </Link>
            .
          </span>
        </div>
      )}

      <div className="mb-10 -mx-6 sm:mx-0">
        <div className="flex flex-col items-start justify-between sm:items-center sm:flex-row">
          <Breadcrumbs
            paths={student.schoolId ? [] : [{ children: 'Dashboard', to: '/dashboard' }]}
          >
            {`${student.firstName} ${student.lastName}`}
          </Breadcrumbs>
          <div className="flex flex-col items-end self-end gap-4 print:hidden">
            <Link
              className="flex items-center gap-2 px-3 py-2 text-xs font-medium text-white uppercase rounded bg-primary whitespace-nowrap"
              to={welcomePath(student)}
              onClick={triggerMixpanelEvent}
            >
              Check In
              <img src="/images/white-rocket.png" alt="check in" className="h-4" />
            </Link>
            {featureEnabled(currentUser, 'message_center') && (
              <Link
                className="flex items-center gap-2 px-2 py-1 text-xs button-contained-light"
                to={`/v2/schools/${student.schoolId}/messages/${student.id}`}
                onClick={triggerMixpanelEvent}
              >
                Send a message
                <MessagesIcon style={{ height: 20, width: 20 }} />
              </Link>
            )}
            {currentTab === 'HISTORY' && isSchoolPremium(school) && isPremium(currentUser) && (
              <>
                <ButtonCSVExport
                  className="px-2 py-1 text-xs button-contained-light"
                  url={`${process.env.REACT_APP_API_URL}/schools/${schoolId}/students/${
                    student.id
                  }/export-check-ins?${map(
                    pickBy(query, identity),
                    (value, key) => `${key}=${value}`,
                  ).join('&')}`}
                />
                <button
                  className="px-2 py-1 text-xs button-contained-light"
                  onClick={handlePrint}
                  type="button"
                >
                  PRINT
                  <PrintIcon fontSize="small" className="ml-2" />
                </button>
              </>
            )}
          </div>
        </div>
        <AdultsOnAccount
          student={student}
          currentUser={currentUser}
          adults={student.adultsOnAccount}
        />
      </div>
    </div>
  )
}

export default EditHeader
