import React from 'react'

import Screen from 'checkIn/components/kid/Screen'
import planetList from 'checkIn/constants/threeToFour/planetList'
import Image from 'components/ResponsiveImage'
import { User } from 'types'

import { HOW_ARE_YOU_FEELING_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  selectEmotionFunction: (params: string, user?: User) => void
  currentUser: User
}

const ThreeToFour = ({ selectEmotionFunction, currentUser }: Props) => {
  return (
    <Screen
      label="Choose 1 Planet:"
      title="How are you feeling now?"
      titleAudio={HOW_ARE_YOU_FEELING_THREE_TO_FOUR.autoplay}
    >
      <div className="flex flex-col w-screen md:mt-10 text-center">
        <div className="container grid grid-cols-2 gap-1 sm:grid-cols-3 md:grid-cols-6">
          {planetList.map(({ feeling, src, label }) => (
            <div key={feeling} className="mt-4 md:mt-10 flex flex-col">
              <AudioPlayer
                importAudio={HOW_ARE_YOU_FEELING_THREE_TO_FOUR[feeling]}
                className="justify-center gap-3 mt-10"
                styleButton={{
                  alignSelf: 'flex-end',
                  marginLeft: -56,
                  marginBottom: 32,
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    selectEmotionFunction(feeling, currentUser)
                  }}
                >
                  <Image src={src} alt={''} className="max-w-[132px] mx-auto block" />
                  <p className="text-xl text-center tracking-wider font-medium text-[#FFF5F5]">
                    {label}
                  </p>
                </button>
              </AudioPlayer>
            </div>
          ))}
        </div>
      </div>
    </Screen>
  )
}

export default ThreeToFour
