import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import Teen from 'checkIn/steps/EnergyLevel/Teen'
import Kid from 'checkIn/steps/EnergyLevel/Kid'
import NonReader from 'checkIn/steps/EnergyLevel/NonReader'
import type { RouteComponent, User } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import { checkInTrack } from 'students/helper'
import { useMixpanel } from 'mixpanel'
import { featureEnabled } from 'featureFlags'
import TeenV2 from './TeenV2'
import ThreeToFour from './ThreeToFour'
import NonReaderV2 from './NonReaderV2'

const SCREEN_NAME = 'energyLevel'

const EnergyLevel: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check in: Pick the color that matches your energy level', student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    const selectFuelFunction = (value: string, user?: User) => {
      setQSData({ energyLevel: value })
      nextFunction(window.location.search, user)
    }

    switch (student.studentType) {
      case 'teen':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <TeenV2 selectFuelFunction={selectFuelFunction} />
        ) : (
          <Teen selectFuelFunction={selectFuelFunction} />
        )
      case 'kid':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <ThreeToFour currentUser={currentUser} selectFuelFunction={selectFuelFunction} />
        ) : (
          <Kid selectFuelFunction={selectFuelFunction} />
        )
      case 'non-reader':
        return featureEnabled(currentUser, 'pk_2_check_in_2024') ? (
          <NonReaderV2 currentUser={currentUser} selectFuelFunction={selectFuelFunction} />
        ) : (
          <NonReader currentUser={currentUser} selectFuelFunction={selectFuelFunction} />
        )
    }
  }

  return null
}

export default EnergyLevel
