import React, { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { XMarkIcon } from '@heroicons/react/24/solid'

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 50vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 0 1rem;
  margin-bottom: 1rem;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(148, 148, 148, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(148, 148, 148, 0.4);
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(148, 148, 148, 1);
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: #353535;
  line-height: 160%;
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`

const Paragraph = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  color: #353535;
  margin-bottom: 1rem;
  .trix-content a {
    color: #0d9488;
    text-decoration: underline;
  }
`

const Link = styled.a`
  color: #0d9488;
  text-decoration: underline;
`

interface Props {
  open: boolean
  onClose: () => void
  customHotlineText?: string
  countryHotlineText?: string
  countryCode: string | undefined
}

export const HotlinesModal = ({
  open,
  onClose,
  customHotlineText,
  countryHotlineText,
  countryCode,
}: Props) => {
  const sanitizedCustomHotlineText =
    // Check in case customHotlineText is undefined
    typeof customHotlineText === 'string' ? DOMPurify.sanitize(customHotlineText) : ''

  const sanitizedCountryHotlineText =
    // Check in case countryHotlineText is undefined
    typeof countryHotlineText === 'string' ? DOMPurify.sanitize(countryHotlineText) : ''

  const defaultUSText = (
    <>
      <Dialog.Title
        as="h3"
        className="text-center text-black text-2xl font-medium font-['Roboto'] leading-[28.80px] mb-8"
      >
        US Hotline Information
      </Dialog.Title>
      <TextContainer>
        <Paragraph>
          If you are in crisis, please call the mental health hotline and <strong>dial 988</strong>.
          You can also text <strong> &apos;HERO&apos;</strong> to <strong>741741</strong>.
        </Paragraph>
        <Paragraph>
          Get help through articles
          <br />
          <ul>
            <li>
              <Link
                href="https://www.inspiringchildren.org/mental-tools-shift-your-focus"
                target="_blank"
              >
                Shift your focus
              </Link>
            </li>
          </ul>
        </Paragraph>
      </TextContainer>
    </>
  )

  const defaultInternationalText = (
    <>
      <Dialog.Title
        as="h3"
        className="text-center text-black text-2xl font-medium font-['Roboto'] leading-[28.80px] mb-8"
      >
        International Hotline Information
      </Dialog.Title>
      <TextContainer>
        <Paragraph>
          If you are in a crisis, please consult the following list and call the mental health
          hotline for your country:
          <br />
          <Link href="https://blog.opencounseling.com/suicide-hotlines/" target="_blank">
            International Crisis Hotlines
          </Link>
        </Paragraph>
      </TextContainer>
    </>
  )

  const schoolCountryHotlineText = (
    <>
      <Dialog.Title
        as="h3"
        className="text-center text-black text-2xl font-medium font-['Roboto'] leading-[28.80px] mb-8"
      >
        Hotline Information
      </Dialog.Title>
      <TextContainer>
        <Paragraph dangerouslySetInnerHTML={{ __html: sanitizedCountryHotlineText }}></Paragraph>
      </TextContainer>
    </>
  )

  const getHotlineText = () => {
    if (sanitizedCountryHotlineText) return schoolCountryHotlineText
    else if (countryCode === 'US') return defaultUSText
    else return defaultInternationalText
  }
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose} open={open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                {getHotlineText()}
                <TextContainer>
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: sanitizedCustomHotlineText }}
                  ></Paragraph>
                </TextContainer>
                <button onClick={() => onClose()} className="absolute top-4 right-4">
                  <XMarkIcon className="text-zinc-600 h-6 w-6" />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
