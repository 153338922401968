import React from 'react'
import { navigate } from '@reach/router'
import { withStyles } from '@material-ui/core/styles'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ArrowButton from './ArrowButton'
import AudioPlayer from '../../AudioPlayer'

const styles = (theme) => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'baseline',
    },
    [theme.breakpoints.up('xs')]: {
      position: 'absolute',
    },
  },
  button: {
    width: 64,
    height: 96,
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '29px',
      width: '118px',
      height: '155px',
    },
  },
  buttonLabel: {
    fontSize: '14px',
    textTransform: 'none',
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      textTransform: 'none',
      display: 'block',
    },
  },
  outerButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      top: 0,
      display: 'block',
      height: '100%',
    },
  },
  arrowRight: {
    fontSize: '58px',
    bottom: 0,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      fontSize: '100px',
      bottom: '50px',
    },
  },
  arrowLeft: {
    fontSize: '58px',
    bottom: 0,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      fontSize: '100px',
      bottom: '50px',
    },
  },
  buttonLeft: {
    position: 'fixed',
    left: 0,
    zIndex: 500,
    top: '80%',
    marginLeft: 4,
    [theme.breakpoints.up('md')]: {
      top: '50%',
      left: '10px',
      marginLeft: 0,
    },
  },
  buttonRight: {
    position: 'fixed',
    right: 0,
    top: '80%',
    marginRight: 4,
    [theme.breakpoints.up('md')]: {
      top: '50%',
      right: '10px',
      marginRight: 0,
    },
  },
})

export const goToUrl = (url) => () => navigate(url)
export const createGoBackFunction = (backUrl) => {
  if (backUrl) {
    return () => navigate(backUrl)
  }
  return () => window.history.back()
}

const BackAndNextButtonsV2 = ({
  classes,
  backUrl = '',
  showBackButton = false,
  backButtonText = 'Back',
  nextUrl = '',
  nextButtonText = 'Next',
  hiddenNextButton = false,
  nextButtonOnClick = () => {},
  width,
  audios = {},
}) => {
  const goBackFunction = createGoBackFunction(backUrl)
  const isMobileScreen = !isWidthUp('md', width)
  const showNextButton = !hiddenNextButton && (nextButtonOnClick || nextUrl)

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.buttonLeft}>
        {showBackButton && (
          <AudioPlayer importAudio={audios.buttonBack ?? null}>
            <ArrowButton onClick={goBackFunction}>
              <div className={classes.outerButton}>
                {isMobileScreen && <ChevronLeft className={classes.arrowLeft} />}
                <div className={classes.buttonLabel}>{backButtonText}</div>
                {!isMobileScreen && <br />}
                {!isMobileScreen && <ChevronLeft className={classes.arrowLeft} />}
              </div>
            </ArrowButton>
          </AudioPlayer>
        )}
      </div>
      <div className={classes.buttonRight}>
        {!!showNextButton && (
          <ArrowButton onClick={nextButtonOnClick || goToUrl(nextUrl)}>
            <div className={classes.outerButton}>
              {isMobileScreen && <ChevronRight className={classes.arrowRight} />}
              <div className={classes.buttonLabel}>{nextButtonText}</div>
              {!isMobileScreen && <br />}
              {!isMobileScreen && <ChevronRight className={classes.arrowRight} />}
            </div>
          </ArrowButton>
        )}
      </div>
    </div>
  )
}

export default withWidth()(withStyles(styles)(BackAndNextButtonsV2))
