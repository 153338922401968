import { navigate } from '@reach/router'
import { isSchoolOnVacation } from 'auth/signIn/vacationHelper'
import { getQSData } from 'QueryStringController'
import { isComfy } from 'emotions'
import parseCheckIn from 'checkIn/parseCheckIn'
import { isWeekendToday, setLocalStorageCheckIn } from 'students/helper'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import { User, Student } from 'types'
import { featureEnabled } from 'featureFlags'

export default {
  welcome: {
    nextFunction: (params: string, _user?: User) => {
      const { impactMeasurement } = getQSData()
      impactMeasurement === 'true' ? navigate(`./impactQ1${params}`) : navigate('.')
    },
  },

  start: { nextFunction: () => navigate('./choose-emotion') },

  chooseEmotion: {
    nextFunction: (params: string, user?: User) =>
      featureEnabled(user, '3-4_check_in_2024')
        ? navigate(`./emotion-intensity${params}`)
        : navigate(`./confirm-emotion${params}`),
  },

  confirmEmotion: { nextFunction: (params: string) => navigate(`./emotion-intensity${params}`) },

  emotionIntensity: { nextFunction: (params: string) => navigate(`./body-sensations${params}`) },

  bodySensations: { nextFunction: (params: string) => navigate(`./energy-level-intro${params}`) },

  energyLevelIntro: { nextFunction: (params: string) => navigate(`./energy-level${params}`) },

  energyLevel: {
    nextFunction: (params: string, user?: User) => {
      if (featureEnabled(user, '3-4_check_in_2024')) {
        const { energyLevel, feeling } = getQSData()

        const isOkOrFullAndEmotionsAreComfy =
          ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)

        const path = isOkOrFullAndEmotionsAreComfy ? 'comfortable-result' : 'uncomfortable-result'
        return navigate(`./${path}${params}`)
      } else navigate(`./energy-level-confirmation${params}`)
    },
  },

  energyLevelConfirmation: {
    nextFunction: (params: string) => {
      const { energyLevel, feeling } = getQSData()

      const isOkOrFullAndEmotionsAreComfy =
        ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)

      const path = isOkOrFullAndEmotionsAreComfy ? 'comfortable-result' : 'uncomfortable-result'
      return navigate(`./${path}${params}`)
    },
  },

  comfortableResult: { nextFunction: (params: string) => navigate(`./choose-activity${params}`) },

  uncomfortableResult: {
    nextFunction: (params: string) => navigate(`./questions/hasEaten${params}`),
  },

  hasEaten: { nextFunction: (params: string) => navigate(`./hasSlept${params}`) },

  hasSlept: { nextFunction: (params: string) => navigate(`./hurtOrSick${params}`) },

  hurtOrSick: { nextFunction: (params: string) => navigate(`./okAtHome${params}`) },

  okAtHome: { nextFunction: (params: string) => navigate(`./bulliedAtSchool${params}`) },

  bulliedAtSchool: {
    nextFunction: (params: string, school?: object, student?: Student) => {
      const skipNotesAndTalkToAdultSteps = () => navigate(`./../talk-to-an-adult-response${params}`)
      if (isWeekendToday()) return skipNotesAndTalkToAdultSteps()
      if (!isWithinSchoolOpeningHours(student?.checkInAvailable))
        return skipNotesAndTalkToAdultSteps()
      if (school && isSchoolOnVacation(school)) return skipNotesAndTalkToAdultSteps()
      return navigate(`./../share-anything-else${params}`)
    },
  },

  shareAnythingElse: { nextFunction: (params: string) => navigate(`./talk-to-an-adult${params}`) },

  talkToAnAdult: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./talk-to-an-adult-response${params}`)
    },
  },

  talkToAnAdultResponse: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./choose-activity${params}`)
    },
  },

  chooseActivity: {
    nextFunction: (params: string, activityKey: string) =>
      navigate(`./activities/${activityKey}${params}`),
  },

  activities: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./../how-are-you-feeling-now${params}`)
    },
  },

  howAreYouFeelingNow: {
    nextFunction: (
      saveCheckIn: (options: any) => (value: any) => void,
      userId: number,
      studentId: number,
    ) => {
      const newValue = parseCheckIn(userId, studentId)
      saveCheckIn({
        afterSuccess: () => {
          setLocalStorageCheckIn('notes', null)
          navigate('./thanks-for-exploring', { replace: true })
        },
      })(newValue)
    },
  },

  thanksForExploring: { nextFunction: () => navigate('/sign-out') },

  impactQ1: { nextFunction: () => navigate('./impactQ2') },
  impactQ2: { nextFunction: () => navigate('./impactQ3') },
  impactQ3: { nextFunction: () => navigate('./impactQ4') },
  impactQ4: { nextFunction: () => navigate('./impactQ5') },
  impactQ5: { nextFunction: () => navigate('./impactQ6') },
  impactQ6: { nextFunction: () => navigate('.') },
} as const
