import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import { SURVEY_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'
import { setLocalStorageCheckIn } from 'students/helper'
import {
  ColorsContainer,
  AnswerHint,
  AnswerHintBottom,
  AnswerHintTop,
  ColorButton,
} from '../components'

import TitleWithAudio from '../components/TitleWithAudio'

type Props = {
  nextFunction: (impactMeasurement?: boolean) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  const [selectedOption, setselectedOption] = React.useState<number | null>(null)
  const color = '#6750A3'

  const onClick = (number: number) => {
    setLocalStorageCheckIn('impactQ2', number)
    setselectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="" className="max-w-4xl mx-auto">
      <TitleWithAudio audio={SURVEY_THREE_TO_FOUR.q2}>
        On a scale from 1 to 5, how easy is it for you to put your feelings into words?
      </TitleWithAudio>

      <ColorsContainer>
        <AudioPlayer
          importAudio={SURVEY_THREE_TO_FOUR.q2Easy}
          styleButton={{ marginBottom: 0, marginLeft: 0, alignSelf: 'unset' }}
        >
          <AnswerHintTop>
            <AnswerHint color="#6750A3">
              Really easy! I can label my feelings and express them when I need to.
            </AnswerHint>
          </AnswerHintTop>
        </AudioPlayer>

        {[1, 2, 3, 4, 5].reverse().map((number: number) => (
          <ColorButton
            key={number}
            background={color}
            number={number}
            selected={selectedOption === number}
            onClick={() => onClick(number)}
          >
            {number}
          </ColorButton>
        ))}
        <AudioPlayer
          importAudio={SURVEY_THREE_TO_FOUR.q2Hard}
          styleButton={{ marginBottom: 0, marginLeft: 0 }}
        >
          <AnswerHintBottom>
            <AnswerHint color="#201637">
              Really hard. I don't know how to put my feelings into words.
            </AnswerHint>
          </AnswerHintBottom>
        </AudioPlayer>
      </ColorsContainer>
      {selectedOption ? (
        <div className="absolute bottom-0 w-full">
          <BackAndNextButtons nextButtonText="Next" nextButtonOnClick={() => nextFunction(true)} />
        </div>
      ) : null}
    </CheckInContainer>
  )
}

export default Kid
