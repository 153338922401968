import React from 'react'
import map from 'lodash/map'

import ButtonNext from 'checkIn/components/ButtonNext'
import Screen from 'checkIn/components/kid/Screen'
import { setQSData } from 'QueryStringController'

import { ACTIVITIES_CONCLUSION_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'

const buttons = [
  {
    src: '/images/feelings/Better.png',
    alt: 'better',
    buttonText: 'Better!',
    value: 'better',
  },
  {
    src: '/images/feelings/Same.png',
    alt: 'same',
    buttonText: 'About the same',
    value: 'same',
  },
  {
    src: '/images/feelings/Worse.png',
    alt: 'worse',
    buttonText: 'Worse',
    value: 'worse',
  },
]

const HowAreYouFeelingNow = ({ nextFunction }) => {
  function onClick(value) {
    setQSData({ feelingNow: value })
    nextFunction()
  }

  return (
    <Screen
      className="h-[100vh] mt-0"
      title={'How are you feeling now?'}
      style={{ justifyContent: 'center' }}
      titleAudio={ACTIVITIES_CONCLUSION_PAGE_THREE_TO_FOUR['how-are-you-feeling-now']}
    >
      <div className="px-10 mt-10 flex flex-col gap-10">
        {map(buttons, ({ src, alt, buttonText, value }) => (
          <AudioPlayer key={value} importAudio={ACTIVITIES_CONCLUSION_PAGE_THREE_TO_FOUR[value]}>
            <div className="flex items-center w-full">
              <img src={src} alt={alt} className="w-10 h-10 mr-5 sm:mr-10 sm:w-20 sm:h-20" />
              <ButtonNext
                className="w-full !text-base sm:!text-4xl"
                onClick={() => onClick(buttonText, value)}
              >
                <span className="font-semibold whitespace-nowrap">{buttonText}</span>
              </ButtonNext>
            </div>
          </AudioPlayer>
        ))}
      </div>
    </Screen>
  )
}

export default HowAreYouFeelingNow
