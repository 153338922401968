import React from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from 'checkIn/steps/Questions/Teen'
import Kid from 'checkIn/steps/Questions/Kid'
import NonReader from 'checkIn/steps/Questions/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const Questions: RouteComponent = () => {
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()
  const { school } = currentUser

  const questionKey = window.location.pathname.split('/').pop()

  if (student) {
    // @ts-ignore
    const { nextFunction } = FLOWS[student.studentType][questionKey || '']

    switch (student.studentType) {
      case 'teen':
        return (
          <Teen
            nextFunction={nextFunction}
            questionKey={questionKey}
            school={school}
            student={student}
          />
        )
      case 'kid':
        return (
          <Kid
            nextFunction={nextFunction}
            questionKey={questionKey}
            school={school}
            student={student}
          />
        )
      case 'non-reader':
        return <NonReader nextFunction={nextFunction} questionKey={questionKey} school={school} />
    }
  }

  return null
}

export default Questions
