import React, { useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'

import { WELCOME_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'

import { useMixpanel } from 'mixpanel'
import SecondaryButton from 'checkIn/components/SecondaryButton'
import CadetOnBottom from 'checkIn/components/kid/CadetOnBottomScreen'
import { ButtonNext } from 'checkIn/components/ButtonNext'
import { WeekendText } from 'checkIn/components/WeekendText'
import FirstCheckInText from 'checkIn/components/FirstCheckInText'
import IconMessage from 'checkIn/components/kid/IconMessage'
// import { checkInTrack } from 'students/helper'
import { checkInTrack, isWeekendToday } from 'students/helper'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import type { RouteComponent, Student } from 'types'

type Props = {
  nextFunction: (params: string) => void
  student: Student
  isStudent?: boolean
  loading: boolean
  showActivities: boolean
  showMessages: boolean
}
const WelcomeKid: RouteComponent<Props> = ({
  nextFunction,
  student,
  isStudent,
  loading,
  showActivities,
  showMessages,
}) => {
  const mixpanel = useMixpanel()
  const { hasUnseenMessages, latestCheckIn, firstName, id, grade, checkInAvailable } = student
  const studentData = { studentId: id, grade }
  const [notificationsVisible, setNotificationsVisible] = useState(false)
  useEffect(() => {
    const handler = (event: { data: any }) => {
      const data = event.data
      if (data.message === 'notifications_badge_controller:connect') {
        setNotificationsVisible(true)
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  // const outsideSchoolHoursV2 = useMemo(() => !checkInAvailable, [checkInAvailable])
  const outsideSchoolHours = useMemo(
    () => isWeekendToday() || !isWithinSchoolOpeningHours(checkInAvailable),
    [checkInAvailable],
  )

  const handleMyProfileButton = (qs: string = '') => {
    checkInTrack(mixpanel, 'View My Profile Clicked', studentData)
    navigate(`/check-in-history/${id}${qs}`)
  }

  const handleNextButton = () => {
    checkInTrack(mixpanel, "Let's Start Clicked", studentData)
    nextFunction(window.location.search)
  }

  const handleActivitiesButton = () => {
    checkInTrack(mixpanel, 'Activities Clicked', studentData)
    navigate(`/activities/${id}/choose-activity`)
  }

  const getWelcomeAudio = () => {
    if (outsideSchoolHours && isStudent) {
      return WELCOME_PAGE_THREE_TO_FOUR.outsideOfHours
    } else if (!latestCheckIn) {
      return WELCOME_PAGE_THREE_TO_FOUR.firstCheckIn
    } else return WELCOME_PAGE_THREE_TO_FOUR.autoplay
  }

  return (
    <CadetOnBottom
      title="Welcome to Closegap"
      text={`It's great to see you, ${firstName}!`}
      audio={getWelcomeAudio()}
    >
      {outsideSchoolHours && isStudent ? (
        <>
          <WeekendText />

          <AudioPlayer importAudio={WELCOME_PAGE_THREE_TO_FOUR.viewProfileButton}>
            <ButtonNext
              onClick={() => handleMyProfileButton()}
              disabled={loading}
              className="font-bold"
            >
              View My Profile
            </ButtonNext>
          </AudioPlayer>
          {showMessages && (
            <AudioPlayer importAudio={WELCOME_PAGE_THREE_TO_FOUR.messagesButton}>
              <ButtonNext
                onClick={() => navigate(`/student/messages`)}
                disabled={loading}
                className="mt-4 font-bold"
              >
                <span className="relative">
                  Messages{' '}
                  <iframe
                    src={`${process.env.REACT_APP_API_URL}/app/notifications`}
                    className="inline absolute min-w-0 min-x-0 w-fit h-fit top-[8.25rem] ml-[6rem] scale-[3.0] max-w-20 pointer-events-none"
                    title="Notifications"
                    style={{ visibility: notificationsVisible ? 'visible' : 'hidden' }}
                  />
                </span>
              </ButtonNext>
            </AudioPlayer>
          )}
          {showActivities && (
            <AudioPlayer importAudio={WELCOME_PAGE_THREE_TO_FOUR.exploreActivities}>
              <ButtonNext
                onClick={() => handleActivitiesButton()}
                disabled={loading}
                className="mt-4 font-bold"
              >
                Explore Activities
              </ButtonNext>
            </AudioPlayer>
          )}
        </>
      ) : (
        <>
          {!latestCheckIn && <FirstCheckInText />}
          <AudioPlayer importAudio={WELCOME_PAGE_THREE_TO_FOUR.buttonStart}>
            <ButtonNext onClick={handleNextButton} disabled={loading}>
              <span className="font-bold">Let's Start!</span>
            </ButtonNext>
          </AudioPlayer>
          {isStudent && (
            <div className="mt-10" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <>
                {hasUnseenMessages && (
                  <IconMessage
                    className="mt-[-16px] ml-[150px]"
                    onClick={() => handleMyProfileButton('?hasUnseenMessage=true')}
                  />
                )}
                <AudioPlayer
                  styleButton={{
                    marginBottom: 0,
                    width: 30,
                    height: 30,
                    minHeight: 30,
                    minWidth: 30,
                    marginLeft: -14,
                  }}
                  importAudio={WELCOME_PAGE_THREE_TO_FOUR.viewProfileButton}
                >
                  <SecondaryButton
                    className="whitespace-nowrap"
                    onClick={() => handleMyProfileButton()}
                  >
                    View My Profile
                  </SecondaryButton>
                </AudioPlayer>
              </>
              {showMessages && (
                <AudioPlayer
                  styleButton={{
                    marginBottom: 0,
                    width: 30,
                    height: 30,
                    minHeight: 30,
                    minWidth: 30,
                    marginLeft: -14,
                  }}
                  importAudio={WELCOME_PAGE_THREE_TO_FOUR.messagesButton}
                >
                  <SecondaryButton
                    className="whitespace-nowrap"
                    onClick={() => navigate(`/student/messages`)}
                  >
                    <span className="relative">
                      Messages{' '}
                      <iframe
                        src={`${process.env.REACT_APP_API_URL}/app/notifications`}
                        className="inline absolute min-w-0 min-x-0 w-fit h-fit top-[-4px] ml-1 max-w-20 pointer-events-none"
                        title="Notifications"
                        style={{ visibility: notificationsVisible ? 'visible' : 'hidden' }}
                      />
                    </span>
                  </SecondaryButton>
                </AudioPlayer>
              )}
              {showActivities && (
                <AudioPlayer
                  styleButton={{
                    marginBottom: 0,
                    width: 30,
                    height: 30,
                    minHeight: 30,
                    minWidth: 30,
                    marginLeft: -14,
                  }}
                  importAudio={WELCOME_PAGE_THREE_TO_FOUR.exploreActivities}
                >
                  <SecondaryButton
                    className="whitespace-nowrap"
                    onClick={() => handleActivitiesButton()}
                  >
                    Explore Activities
                  </SecondaryButton>
                </AudioPlayer>
              )}
            </div>
          )}
        </>
      )}
    </CadetOnBottom>
  )
}

export { WelcomeKid }
