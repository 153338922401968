import React from 'react'
import Title from 'components/Title'
import clsx from 'clsx'

import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  children?: React.ReactNode
  label?: string
  title?: string
  titleAudio?: string
  className?: string
}

export default ({ children, label, title, titleAudio, className }: Props) => {
  return (
    <main className={clsx('flex flex-col justify-center items-center pt-[6vh]', className)}>
      {label && (
        <h2 className="px-16 text-2xl font-medium text-center text-primary mb-7">{label}</h2>
      )}
      <AudioPlayer
        importAudio={titleAudio}
        styleButton={{ alignSelf: 'flex-start', marginRight: 16 }}
      >
        {title && <Title style={{ fontWeight: 400, maxWidth: 1008, flex: 1 }}>{title}</Title>}
      </AudioPlayer>
      {children}
    </main>
  )
}
