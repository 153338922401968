import React from 'react'

import { Title, TitleContainer } from './index'

import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  audio: string
  audioButtonStyle?: object
}

const TitleWithAudio: React.FC<Props> = ({ children, audio, audioButtonStyle }) => (
  <TitleContainer>
    <AudioPlayer importAudio={audio} styleButton={audioButtonStyle}>
      <Title style={{ marginBottom: 0 }}>{children}</Title>
    </AudioPlayer>
  </TitleContainer>
)

export default TitleWithAudio
