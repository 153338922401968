import React, { useEffect } from 'react'
import withMixpanel from 'mixpanel/withMixpanel'
import { kebabCase } from 'lodash'

import { checkInTrack } from 'students/helper'
import Screen from 'checkIn/components/kid/Screen'
import { setQSData } from 'QueryStringController'
import { QUESTIONS_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'

import CheckInButtonGroup from './CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/kid/contentByQuestion'

const Kid = withMixpanel(({ mixpanel, nextFunction, questionKey, school, student }) => {
  const question = contentByQuestion[questionKey]
  const { image, audio } = question

  useEffect(() => {
    checkInTrack(mixpanel, question.eventName)
  }, []) // eslint-disable-line

  return (
    <Screen
      title={question.title}
      className="h-screen mt-0"
      titleAudio={QUESTIONS_PAGE_THREE_TO_FOUR[kebabCase(questionKey)]}
    >
      <img src={image.src} alt={image.alt} className="mt-10 mb-20" />
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school, student)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school, student)
        }}
        audio={audio}
      />
    </Screen>
  )
})

export default Kid
