import React from 'react'

import { DashboardScreenV2 } from 'components/DashboardScreenV2'
import validateUserFields from 'auth/validateUserFields'

import type { User } from 'types'

type Props = { currentUser: User; schoolId: string; checkInId: string }
const CheckInDetails = ({ currentUser, schoolId, checkInId }: Props) => {
  return (
    <DashboardScreenV2
      currentUser={currentUser}
      propsHeader={{
        propsMenuSchool: {
          shoAllPremiumSchools: false,
          navigateOnChange: true,
          schoolIdFromURL: schoolId,
          navigateOnChangeFn(school) {
            return `/v2/schools/${school}/home`
          },
        },
      }}
    >
      <iframe
        src={`${process.env.REACT_APP_API_URL}/app/educators/schools/${schoolId}/check_ins/${checkInId}`}
        className="h-max w-full min-h-[calc(100dvh-88px)]"
        title="Check-in Details"
      />
    </DashboardScreenV2>
  )
}

export default validateUserFields(CheckInDetails)
