// Welcome
import WelcomePageThreeToFour from 'assets/check-ins/three-to-four/students_1st_access_page_a.mp3'
import WelcomePageFirstCheckInThreeToFour from 'assets/check-ins/three-to-four/students_1st_access_page.mp3'
import WelcomePageCTAThreeToFour from 'assets/check-ins/three-to-four/Lets_start_button.mp3'
import ViewMyProfileButtonThreeToFour from 'assets/check-ins/three-to-four/view_my_profile_button.mp3'
import MessagesButtonThreeToFour from 'assets/check-ins/three-to-four/Messages_button.mp3'
import ExploreActivitiesButtonThreeToFour from 'assets/check-ins/three-to-four/Explore_activities_button.mp3'

// Survey
import SurveyQ1ThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_9.mp3'
import SurveyQ1EasyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_10.mp3'
import SurveyQ1HardThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_11.mp3'
import SurveyQ2ThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_12.mp3'
import SurveyQ2EasyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_13.mp3'
import SurveyQ2HardThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_14.mp3'
import SurveyQ3ThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_15.mp3'
import SurveyQ3EasyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_16.mp3'
import SurveyQ3HardThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_17.mp3'
import SurveyQ4ThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_18.mp3'
import SurveyQ4EasyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_19.mp3'
import SurveyQ4HardThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_20.mp3'
import SurveyQ5ThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_21.mp3'
import SurveyQ5NoneThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_22.mp3'
import SurveyQ5OneAdultThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_3-4Check-In_23.mp3'
import SurveyQ5TwoOrThreeAdultsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_24.mp3'
import SurveyQ5FourOrMoreAdultsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_25.mp3'
import SurveyShareYourThoughtsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_26.mp3'

// Check-in welcome page
import CheckInWelcomePageThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_28.mp3'
import CheckInReadyToGoThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_29.mp3'

// Feelings
import FeelingsWelcomePageThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_31.mp3'
import OptionsHappyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_32.mp3'
import OptionsHopefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_33.mp3'
import OptionsLovedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_34.mp3'
import OptionsExcitedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_35.mp3'
import OptionsCalmThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_36.mp3'
import OptionsGratefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_37.mp3'
import OptionsContentThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_38.mp3'
import OptionsBoredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_39.mp3'
import OptionsSadThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_40.mp3'
import OptionsAngryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_41.mp3'
import OptionsScaredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_42.mp3'
import OptionsOverwhelmedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_43.mp3'

// Feelings, scale
import ScaleHappyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_44.mp3'
import ScaleHopefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_45.mp3'
import ScaleLovedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_46.mp3'
import ScaleExcitedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_47.mp3'
import ScaleCalmThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_48.mp3'
import ScaleGratefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_49.mp3'
import ScaleContentThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_50.mp3'
import ScaleBoredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_51.mp3'
import ScaleSadThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_52.mp3'
import ScaleAngryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_53.mp3'
import ScaleScaredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_54.mp3'
import ScaleOverwhelmedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_55.mp3'

// Somatic sensations
import SensationsHappyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_57.mp3'
import SentationsClearOrAwakeThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_58.mp3'
import SensationsWarmOrRelaxedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_59.mp3'
import SensationsTickleyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_60.mp3'
import SensationsFloatyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_61.mp3'
import SensationsBubblyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_62.mp3'
import SensationsBuzzyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_63.mp3'
import SensationsHopefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_65.mp3'
import SensationsWarmThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_66.mp3'
import SensationsSteadyOrStrongThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_67.mp3'
import SensationsLightThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_68.mp3'
import SensationsRelaxedOrAtEaseThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_69.mp3'
import SensationsLovedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_71.mp3'
import SensationsEnergizedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_72.mp3'
import SensationsTinglyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_73.mp3'
import SensationsHeartFluttersThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_74.mp3'
import SensationsExcitedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_76.mp3'
import SensationsElectricThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_77.mp3'
import SensationsCalmThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_79.mp3'
import SensationsGratefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_81.mp3'
import SensationsContentThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_83.mp3'
import SensationsBoredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_85.mp3'
import SensationsAgitatedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_86.mp3'
import SensationsNumbThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_87.mp3'
import SensationsFuzzyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_88.mp3'
import SensationsEmptyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_89.mp3'
import SensationsDullThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_90.mp3'
import SensationsQuietAndNeutralThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_91.mp3'
import SensationsSadThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_93.mp3'
import SensationsWeakThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_94.mp3'
import SensationsKnottedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_95.mp3'
import SensationsStuckOrFrozenThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_96.mp3'
import SensationsAchyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_97.mp3'
import SensationsAngryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_99.mp3'
import SensationsSweatyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_100.mp3'
import SensationsStiffOrTenseThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_101.mp3'
import SensationsPricklyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_102.mp3'
import SensationsShakeyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_103.mp3'
import SensationsHotOrBurningThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_104.mp3'
import SensationsBreathlessThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_105.mp3'
import SensationsScaredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_107.mp3'
import SensationsOverwhelmedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_109.mp3'
import SensationsJumbledUpThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_110.mp3'
import SensationsConfusedOrFuzzyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_111.mp3'
import SensationsChurningThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_112.mp3'

// Somatic sensations, confirmation
import SensationsConfirmationHappyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_64.mp3'
import SensationsConfirmationHopefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_70.mp3'
import SensationsConfirmationLovedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_75.mp3'
import SensationsConfirmationExcitedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_78.mp3'
import SensationsConfirmationCalmThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_80.mp3'
import SensationsConfirmationGratefulThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_82.mp3'
import SensationsConfirmationContentThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_84.mp3'
import SensationsConfirmationBoredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_92.mp3'
import SensationsConfirmationSadThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_98.mp3'
import SensationsConfirmationAngryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_106.mp3'
import SensationsConfirmationScaredThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_108.mp3'
import SensationsConfirmationOverwhelmedThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_113.mp3'

// Energy levels
import EnergyLevelsWelcomePageThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_115.mp3'
import EnergyLevelsInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_116.mp3'
import EnergyLevelEmptyThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_117.mp3'
import EnergyLevelLowThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_118.mp3'
import EnergyLevelOkThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_119.mp3'
import EnergyLevelFullThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_120.mp3'
import EnergyLevelWayTooMuchThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_121.mp3'

// Questions
import DoingWellThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_123.mp3'
import NotDoingWellThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_125.mp3'
import AreYouHungryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_126.mp3'
import NotHungryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_127.mp3'
import IsHungryThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_128.mp3'
import DidYouSleepThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_129.mp3'
import SleptWellThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_130.mp3'
import DidNotSleepWellThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_131.mp3'
import AreYouHurtThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_132.mp3'
import IsHurtThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_133.mp3'
import NotHurtThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_134.mp3'
import AreYouOkAtHomeThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_135.mp3'
import IsOkAtHomeThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_136.mp3'
import NotOkAtHomeThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_137.mp3'
import AreYouBulliedAtSchoolThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_138.mp3'
import IsBulliedAtSchoolThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_139.mp3'
import NotBulliedAtSchoolThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_140.mp3'
import ProvideMoreInfoThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_141.mp3'
import DoYouWantToTalkThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_142.mp3'
import WantsToTalkThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_143.mp3'
import DoesNotWantToTalkThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_144.mp3'
import ConfirmationYesThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_145.mp3'
import ConfirmationYesButtonThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_146.mp3'
import ConfirmationNoThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_147.mp3'
import ConfirmationNoButtonThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_Row148.mp3'

// Activities
import ChooseActivityThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_148.mp3'
import ActivitiesBreathingThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_149.mp3'
import ActivitiesBreathingInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_150.mp3'
import ActivitiesLetItGoThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_151.mp3'
import ActivitiesLetItGoInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_152.mp3'
import ActivitiesShakeItOffThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_153.mp3'
import ActivitiesShakeItOffInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_154.mp3'
import ActivitiesListThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_155.mp3'
import ActivitiesListInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_156.mp3'
import ActivitiesPowerPoseThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_157.mp3'
import ActivitiesPowerPoseInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_158.mp3'
import ActivitiesJournalingThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_159.mp3'
import ActivitiesJournalingInstructionsThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_160.mp3'

// Activities conclusion
import ActivitiesConclusionHowAreYouFeelingNowThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_HowAreYouFeelingNow.mp3'
import ActivitiesConclusionBetterThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_Better.mp3'
import ActivitiesConclusionSameThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_AboutTheSame.mp3'
import ActivitiesConclusionWorseThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_Worse.mp3'

// Conclusion
import EndOfCheckInStudent from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_167-169.mp3'
import EndOfCheckInEducator from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_167-168.mp3'
import EndOfCheckInGoToProfile from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_Row174.mp3'
import EndOfCheckInLogOut from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_175.mp3'

// Outside of hours
import OutsideOfHoursThreeToFour from 'assets/check-ins/three-to-four/SarahSwitek_Closegap_Cadet_3-4Check-In_171.mp3'

export const WELCOME_PAGE_THREE_TO_FOUR = {
  autoplay: WelcomePageThreeToFour,
  firstCheckIn: WelcomePageFirstCheckInThreeToFour,
  buttonStart: WelcomePageCTAThreeToFour,
  viewProfileButton: ViewMyProfileButtonThreeToFour,
  messagesButton: MessagesButtonThreeToFour,
  exploreActivities: ExploreActivitiesButtonThreeToFour,
  outsideOfHours: OutsideOfHoursThreeToFour,
}

export const SURVEY_THREE_TO_FOUR = {
  q1: SurveyQ1ThreeToFour,
  q1Easy: SurveyQ1EasyThreeToFour,
  q1Hard: SurveyQ1HardThreeToFour,
  q2: SurveyQ2ThreeToFour,
  q2Easy: SurveyQ2EasyThreeToFour,
  q2Hard: SurveyQ2HardThreeToFour,
  q3: SurveyQ3ThreeToFour,
  q3Easy: SurveyQ3EasyThreeToFour,
  q3Hard: SurveyQ3HardThreeToFour,
  q4: SurveyQ4ThreeToFour,
  q4Easy: SurveyQ4EasyThreeToFour,
  q4Hard: SurveyQ4HardThreeToFour,
  q5: SurveyQ5ThreeToFour,
  None: SurveyQ5NoneThreeToFour,
  '1 adult': SurveyQ5OneAdultThreeToFour,
  '2 or 3 adults': SurveyQ5TwoOrThreeAdultsThreeToFour,
  '4 or more adults': SurveyQ5FourOrMoreAdultsThreeToFour,
  shareYourThoughts: SurveyShareYourThoughtsThreeToFour,
}

export const CHECK_IN_WELCOME_PAGE_THREE_TO_FOUR = {
  autoplay: CheckInWelcomePageThreeToFour,
  readyToGo: CheckInReadyToGoThreeToFour,
}

export const HOW_ARE_YOU_FEELING_THREE_TO_FOUR = {
  autoplay: FeelingsWelcomePageThreeToFour,
  angry: OptionsAngryThreeToFour,
  bored: OptionsBoredThreeToFour,
  calm: OptionsCalmThreeToFour,
  content: OptionsContentThreeToFour,
  excited: OptionsExcitedThreeToFour,
  grateful: OptionsGratefulThreeToFour,
  happy: OptionsHappyThreeToFour,
  hopeful: OptionsHopefulThreeToFour,
  loved: OptionsLovedThreeToFour,
  overwhelmed: OptionsOverwhelmedThreeToFour,
  sad: OptionsSadThreeToFour,
  scared: OptionsScaredThreeToFour,
  // buttonNext: ConfirmationYes,
  // buttonBack: ConfirmationNo,
}

export const FEELING_SCALE_THREE_TO_FOUR = {
  happy: ScaleHappyThreeToFour,
  hopeful: ScaleHopefulThreeToFour,
  loved: ScaleLovedThreeToFour,
  excited: ScaleExcitedThreeToFour,
  calm: ScaleCalmThreeToFour,
  grateful: ScaleGratefulThreeToFour,
  content: ScaleContentThreeToFour,
  bored: ScaleBoredThreeToFour,
  sad: ScaleSadThreeToFour,
  angry: ScaleAngryThreeToFour,
  scared: ScaleScaredThreeToFour,
  overwhelmed: ScaleOverwhelmedThreeToFour,
  // buttonNext: ConfirmationYes,
  // buttonBack: ConfirmationNo,
}

export const SOMATIC_SENSATIONS_THREE_TO_FOUR = {
  happy: SensationsHappyThreeToFour,
  hopeful: SensationsHopefulThreeToFour,
  loved: SensationsLovedThreeToFour,
  excited: SensationsExcitedThreeToFour,
  calm: SensationsCalmThreeToFour,
  grateful: SensationsGratefulThreeToFour,
  content: SensationsContentThreeToFour,
  bored: SensationsBoredThreeToFour,
  sad: SensationsSadThreeToFour,
  angry: SensationsAngryThreeToFour,
  scared: SensationsScaredThreeToFour,
  overwhelmed: SensationsOverwhelmedThreeToFour,

  // options
  'clear-or-awake': SentationsClearOrAwakeThreeToFour,
  'warm-or-relaxed': SensationsWarmOrRelaxedThreeToFour,
  tickley: SensationsTickleyThreeToFour,
  floaty: SensationsFloatyThreeToFour,
  bubbly: SensationsBubblyThreeToFour,
  buzzy: SensationsBuzzyThreeToFour,
  warm: SensationsWarmThreeToFour,
  'steady-or-strong': SensationsSteadyOrStrongThreeToFour,
  light: SensationsLightThreeToFour,
  'relaxed-or-at-ease': SensationsRelaxedOrAtEaseThreeToFour,
  energized: SensationsEnergizedThreeToFour,
  tingly: SensationsTinglyThreeToFour,
  'heart-flutters': SensationsHeartFluttersThreeToFour,
  electric: SensationsElectricThreeToFour,
  agitated: SensationsAgitatedThreeToFour,
  numb: SensationsNumbThreeToFour,
  fuzzy: SensationsFuzzyThreeToFour,
  empty: SensationsEmptyThreeToFour,
  dull: SensationsDullThreeToFour,
  'quiet-and-neutral': SensationsQuietAndNeutralThreeToFour,
  weak: SensationsWeakThreeToFour,
  knotted: SensationsKnottedThreeToFour,
  'stuck-or-frozen': SensationsStuckOrFrozenThreeToFour,
  achy: SensationsAchyThreeToFour,
  sweaty: SensationsSweatyThreeToFour,
  'stiff-or-tense': SensationsStiffOrTenseThreeToFour,
  prickly: SensationsPricklyThreeToFour,
  shakey: SensationsShakeyThreeToFour,
  'hot-or-burning': SensationsHotOrBurningThreeToFour,
  breathless: SensationsBreathlessThreeToFour,
  'jumbled-up': SensationsJumbledUpThreeToFour,
  'confused-or-fuzzy': SensationsConfusedOrFuzzyThreeToFour,
  churning: SensationsChurningThreeToFour,
}

export const CONFIRM_BODY_SENSATION_PAGE_THREE_TO_FOUR = {
  happy: SensationsConfirmationHappyThreeToFour,
  hopeful: SensationsConfirmationHopefulThreeToFour,
  loved: SensationsConfirmationLovedThreeToFour,
  excited: SensationsConfirmationExcitedThreeToFour,
  calm: SensationsConfirmationCalmThreeToFour,
  grateful: SensationsConfirmationGratefulThreeToFour,
  content: SensationsConfirmationContentThreeToFour,
  bored: SensationsConfirmationBoredThreeToFour,
  sad: SensationsConfirmationSadThreeToFour,
  angry: SensationsConfirmationAngryThreeToFour,
  scared: SensationsConfirmationScaredThreeToFour,
  overwhelmed: SensationsConfirmationOverwhelmedThreeToFour,
}

export const ENERGY_LEVELS_THREE_TO_FOUR = {
  autoplay: EnergyLevelsWelcomePageThreeToFour,
  instructions: EnergyLevelsInstructionsThreeToFour,
  red: EnergyLevelEmptyThreeToFour,
  blue: EnergyLevelLowThreeToFour,
  yellow: EnergyLevelOkThreeToFour,
  green: EnergyLevelFullThreeToFour,
  orange: EnergyLevelWayTooMuchThreeToFour,
}

export const QUESTIONS_PAGE_THREE_TO_FOUR = {
  doingWell: DoingWellThreeToFour,
  notDoingWell: NotDoingWellThreeToFour,
  'has-eaten': AreYouHungryThreeToFour,
  'not-hungry': NotHungryThreeToFour,
  hungry: IsHungryThreeToFour,
  'has-slept': DidYouSleepThreeToFour,
  'slept-well': SleptWellThreeToFour,
  'did-not-sleep-well': DidNotSleepWellThreeToFour,
  'hurt-or-sick': AreYouHurtThreeToFour,
  'is-hurt': IsHurtThreeToFour,
  'not-hurt': NotHurtThreeToFour,
  'ok-at-home': AreYouOkAtHomeThreeToFour,
  'is-ok-at-home': IsOkAtHomeThreeToFour,
  'not-ok-at-home': NotOkAtHomeThreeToFour,
  'bullied-at-school': AreYouBulliedAtSchoolThreeToFour,
  'is-bullied': IsBulliedAtSchoolThreeToFour,
  'not-bullied': NotBulliedAtSchoolThreeToFour,
  provideMoreInfo: ProvideMoreInfoThreeToFour,
  doYouWantToTalk: DoYouWantToTalkThreeToFour,
  wantsToTalk: WantsToTalkThreeToFour,
  doesNotWantToTalk: DoesNotWantToTalkThreeToFour,
  yes: ConfirmationYesThreeToFour,
  okThanks: ConfirmationYesButtonThreeToFour,
  no: ConfirmationNoThreeToFour,
  sure: ConfirmationNoButtonThreeToFour,
}

export const ACTIVITIES_PAGE_THREE_TO_FOUR = {
  chooseActivity: ChooseActivityThreeToFour,
  breathing: ActivitiesBreathingThreeToFour,
  breathingInstructions: ActivitiesBreathingInstructionsThreeToFour,
  'let-it-go': ActivitiesLetItGoThreeToFour,
  'let-it-go-instructions': ActivitiesLetItGoInstructionsThreeToFour,
  'shake-it-out': ActivitiesShakeItOffThreeToFour,
  'shake-it-out-instructions': ActivitiesShakeItOffInstructionsThreeToFour,
  list: ActivitiesListThreeToFour,
  'list-instructions': ActivitiesListInstructionsThreeToFour,
  'power-pose': ActivitiesPowerPoseThreeToFour,
  'power-pose-instructions': ActivitiesPowerPoseInstructionsThreeToFour,
  journaling: ActivitiesJournalingThreeToFour,
  'journaling-instructions': ActivitiesJournalingInstructionsThreeToFour,
}

export const ACTIVITIES_CONCLUSION_PAGE_THREE_TO_FOUR = {
  'how-are-you-feeling-now': ActivitiesConclusionHowAreYouFeelingNowThreeToFour,
  better: ActivitiesConclusionBetterThreeToFour,
  same: ActivitiesConclusionSameThreeToFour,
  worse: ActivitiesConclusionWorseThreeToFour,
}

export const END_OF_CHECK_IN_PAGE_THREE_TO_FOUR = {
  educatorGreen: EndOfCheckInEducator,
  studentGreen: EndOfCheckInStudent,
  educatorOther: EndOfCheckInEducator,
  studentOther: EndOfCheckInStudent,
  goToMyProfile: EndOfCheckInGoToProfile,
  logOut: EndOfCheckInLogOut,
}
