import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

import { SURVEY_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'

import { setLocalStorageCheckIn } from 'students/helper'
import {
  ColorsContainer,
  AnswerHint,
  AnswerHintBottom,
  AnswerHintTop,
  ColorButton,
} from '../components'
import TitleWithAudio from '../components/TitleWithAudio'

type Props = {
  nextFunction: (impactMeasurement?: boolean) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  const [selectedOption, setselectedOption] = React.useState<number | null>(null)
  const color = '#6750A3'

  const onClick = (number: number) => {
    setLocalStorageCheckIn('impactQ3', number)
    setselectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="" className="max-w-4xl mx-auto">
      <TitleWithAudio audio={SURVEY_THREE_TO_FOUR.q3}>
        On a scale from 1 to 5, do you feel you can calm your emotions if necessary?
      </TitleWithAudio>
      <ColorsContainer>
        <AudioPlayer
          importAudio={SURVEY_THREE_TO_FOUR.q3Easy}
          styleButton={{ marginBottom: 0, marginLeft: 0, alignSelf: 'unset' }}
        >
          <AnswerHintTop>
            <AnswerHint color="#6750A3">
              Yes, I'm really good at calming myself down when I need to.
            </AnswerHint>
          </AnswerHintTop>
        </AudioPlayer>

        {[1, 2, 3, 4, 5].reverse().map((number: number) => (
          <ColorButton
            key={number}
            background={color}
            number={number}
            selected={selectedOption === number}
            onClick={() => onClick(number)}
          >
            {number}
          </ColorButton>
        ))}
        <AudioPlayer
          importAudio={SURVEY_THREE_TO_FOUR.q3Hard}
          styleButton={{ marginBottom: 0, marginLeft: 0 }}
        >
          <AnswerHintBottom>
            <AnswerHint color="#201637">
              No, I really struggle to calm myself down when I'm feeling big emotions.
            </AnswerHint>
          </AnswerHintBottom>
        </AudioPlayer>
      </ColorsContainer>
      {selectedOption ? (
        <div className="absolute bottom-0 w-full">
          <BackAndNextButtons nextButtonText="Next" nextButtonOnClick={() => nextFunction(true)} />
        </div>
      ) : null}
    </CheckInContainer>
  )
}

export default Kid
