import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import ButtonNext from 'checkIn/components/ButtonNext'
import { ContentByActivity } from 'checkIn/steps/Activities/Kid'
import { ACTIVITIES_PAGE_THREE_TO_FOUR } from 'checkIn/audiosThreeToFour'
import AudioPlayer from 'checkIn/AudioPlayer'

export type Activity = keyof typeof ContentByActivity

type Props = {
  selectActivityFunction: (value: Activity) => void
  label: string
}

const Kid = ({ selectActivityFunction, label }: Props) => {
  const buttonTextClassNames = 'w-64 px-2 md:px-4 font-bold md:w-80'

  return (
    <Screen
      label={label}
      title="Which activity sounds good to you right now?"
      titleAudio={ACTIVITIES_PAGE_THREE_TO_FOUR.chooseActivity}
    >
      <div className="grid grid-cols-1 gap-8 py-10 md:py-16 md:gap-12 md:grid-cols-2">
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR.breathing}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('breathing')}
          >
            Breathing!
          </ButtonNext>
        </AudioPlayer>
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR['let-it-go']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('let-it-go')}
          >
            Let it go!
          </ButtonNext>
        </AudioPlayer>
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR['shake-it-out']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('shake-it-out')}
          >
            Shake it Out!
          </ButtonNext>
        </AudioPlayer>
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR.list}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('lets-make-a-list')}
          >
            Let's make a list!
          </ButtonNext>
        </AudioPlayer>
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR['power-pose']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('power-pose')}
          >
            Power Pose
          </ButtonNext>
        </AudioPlayer>
        <AudioPlayer importAudio={ACTIVITIES_PAGE_THREE_TO_FOUR['journaling']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('journaling')}
          >
            Journaling
          </ButtonNext>
        </AudioPlayer>
      </div>
    </Screen>
  )
}

export default Kid
