import clsx from 'clsx'
import React from 'react'
import AudioPlayer from 'checkIn/AudioPlayer'

type Props = {
  className?: string
  audio?: string
  audioButtonStyle?: object
}

const WeekendText: React.FC<Props> = ({ className, audio, audioButtonStyle }) => (
  <AudioPlayer
    importAudio={audio}
    styleButton={{
      alignSelf: 'center',
      marginLeft: 0,
      ...audioButtonStyle,
    }}
  >
    <p
      className={clsx(
        'text-center mb-10 text-lg max-w-[533px] text-[#616161] font-medium ',
        className,
      )}
    >
      It's after school hours or the weekend! Feel free to view your profile, but you won't be able
      to check in until the next school day. We love you!
    </p>
  </AudioPlayer>
)

export { WeekendText }
