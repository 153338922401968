import React from 'react'
import { navigate } from '@reach/router'
import { useFlash } from 'seasoned-flash'
import CircularProgress from '@material-ui/core/CircularProgress'

import { DashboardScreen } from 'components/DashboardScreen'
import EditStudent from 'Educators/students/edit/EditStudent'
import { useCroods } from 'croods'
import validateUserFields from 'auth/validateUserFields'
import { snakeCase } from 'lodash'

export const renderLoading = () => (
  <div style={{ margin: 50, textAlign: 'center' }}>
    <CircularProgress size="60px" color="primary" />
  </div>
)

const Edit = ({ ...props }) => {
  // parse the query string get the tab param if it exists
  // this is used to set the active tab when the page loads
  // if no tab is provided, the default tab is "HISTORY"
  const urlParams = new URLSearchParams(window.location.search)
  const tab = urlParams.get('tab') || 'HISTORY'

  const { success } = useFlash()
  const [{ info, saving, saveError, fetchingInfo }, { save }] = useCroods({
    name: 'students',
    id: props.studentId,
    fetchOnMount: true,
  })

  const editProps = {
    info,
    update: save({
      method: 'put',
      afterSuccess: () => {
        success('Updated student successfully')
        navigate('/dashboard')
      },
      parseParams: (value) =>
        value !== 'educationPlan504' ? snakeCase(value) : 'education_plan504',
    }),
    updating: saving,
    error: saveError,
  }

  return (
    <DashboardScreen
      {...props}
      propsHeader={{ propsMenuSchool: { disabled: true } }}
      propsFooter={{ className: 'print:hidden' }}
    >
      {fetchingInfo || !info ? (
        renderLoading()
      ) : (
        <EditStudent initialState={tab} editProps={editProps} {...props} />
      )}
    </DashboardScreen>
  )
}

export default validateUserFields(Edit)
