import React, { useEffect } from 'react'
import withMixpanel from 'mixpanel/withMixpanel'

import { checkInTrack } from 'students/helper'
import Screen from 'checkIn/components/teen/Screen'
import { setQSData } from 'QueryStringController'

import CheckInButtonGroup from 'checkIn/steps/Questions/Teen/CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/teen/contentByQuestion'

const Teen = withMixpanel(({ mixpanel, nextFunction, questionKey, school, student }) => {
  const question = contentByQuestion[questionKey]

  useEffect(() => {
    checkInTrack(mixpanel, question.eventName)
  }, []) // eslint-disable-line

  return (
    <Screen
      title={question.title}
      style={{ justifyContent: 'center' }}
      contentWrapperClassName="h-screen !mt-0"
    >
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school, student)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school, student)
        }}
      />
    </Screen>
  )
})

export default Teen
